import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import { Layout } from "src/components/Layout"
import { Helmet } from "src/components/Helmet"
import { Container } from "src/components/Container"
import { ProductCard } from "src/components/ProductCard"

const CategoryTemplate = ({
  data: {
    category: { name, subcategory, product },
  },
}) => {
  return (
    <>
      <Helmet title={name}></Helmet>
      <Layout>
        <Container>
          <Heading>{name}</Heading>
          <Subcategories>
            {subcategory.map(({ shortName, slug, id }) => (
              <Subcategory key={id}>
                <Link to={`/subcategory/${slug}`}>{shortName}</Link>
              </Subcategory>
            ))}
          </Subcategories>

          <Inner>
            {product.length > 0 &&
              product.map(({ id, slug, image, name, price }) => (
                <ProductCard
                  key={id}
                  {...{ slug, image, name, price }}
                ></ProductCard>
              ))}
          </Inner>
        </Container>
      </Layout>
    </>
  )
}

export default CategoryTemplate

export const query = graphql`
  query($id: String!) {
    category: datoCmsCategory(id: { eq: $id }) {
      name
      subcategory {
        shortName
        slug
        id
      }
      product {
        name
        slug
        id
        image {
          fluid(
            maxWidth: 320
            imgixParams: { fm: "jpg", auto: "compress, format" }
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
        price
      }
    }
  }
`

const Heading = styled.h1`
  background-color: #fff;
  border-radius: 6px;
  padding: 16px;
  font-weight: bold;
  font-size: 32px;

  color: ${({ theme }) => theme.colors.contrast};
`

const Subcategories = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-bottom: 40px;
`

const Subcategory = styled.div`
  background-color: #fff;
  border-radius: 6px;
  flex-basis: 49%;
  margin-bottom: 24px;

  a {
    transition: all 0.2s;
    font-size: 18px;
    display: block;
    padding: 16px;

    &:first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    flex-basis: 100%;
  }
`

const Inner = styled.div`
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
  flex-wrap: wrap;

  margin-bottom: 40px;
`
